/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Divider, Box } from '@material-ui/core';

import { Header, Content, BusinessFooter } from '../src/components/layouts';
import SEO from '../src/components/seo';
// TODO Hook up DOMpuriffy

const Legal = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <>
      <SEO title={frontmatter.title} />
      <Header logo />
      <Divider />
      <Box py={2}>
        <Content>
          <h2>{frontmatter.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Content>
      </Box>
      <BusinessFooter />
    </>
  );
};

Legal.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
      }),
      html: PropTypes.string,
    }),
  }).isRequired,
};

export default Legal;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;
